<template>
  <div>
    <CRow>
      <CCol md="12">
        <p style="text-align: center;"><img src="main_logo.png" style="width: 140px; margin: 0 auto;" alt="Logo Oscuro"></p>
        <h1 style="text-align: center;">Bienvenido</h1>
        <p style="text-align: center;" v-if="customer.name">{{customer.name.toUpperCase()}}</p>
        <hr />
        <CCard v-for="enrollment in enrollments" v-if="showing == 0 || showing == enrollment.enrollment_id">
          <CCardHeader>
            <b>{{enrollment.travel_name}}</b><br />
            <b><a :href="enrollment.travel_form.presentation_link" target="_blank">Presentación del Viaje</a></b><br /><br />
            {{enrollment.student_name.toUpperCase()}}<br />
            
          </CCardHeader>
          <CCardBody>
            <CRow v-if="showing > 0">
              <CCol md="12">
                <p v-if="payments.length > 0">
                  <span style="font-weight: bold;">PAGOS</span><br />
                </p>
                <p v-if="payments.length == 0">NO SE HAN DEFINIDO LOS PAGOS</p>
                <hr />
                <table v-for="payment in payments" style="width: 100%">
                  <tr>
                    <td>
                      {{payment.name}}
                    </td>
                    <td></td>
                    <td style="text-align: right; width: 95px; border: 0px solid;" v-if="payment.amount > 100 && payment.amount != 99999 && payment.amount != 88888">{{enrollment.type != 'national' ? 'USD' : ''}} ${{payment.amount | icurrency}} {{enrollment.type != 'national' ? '' : 'Pesos'}}</td>
                    <td style="text-align: right; width: 95px; border: 0px solid;" v-if="payment.amount > 0 && payment.amount <= 100">{{parseInt(payment.amount)}}%</td>
                    <td style="text-align: right; width: 95px; border: 0px solid;" v-if="payment.amount == 99999">PENDIENTE</td>
                    <td style="text-align: right; width: 95px; border: 0px solid;" v-if="payment.amount == 88888">RESTO</td>
                  </tr>
                  <tr v-if="payment.bank_account && payment.status != 'ACEPTADO'">
                    <td colspan="3" style="text-align: right">
                      <br />
                      <div style="padding-left: 10px; font-size: 12px;"><strong>Realiza este pago a la siguiente cuenta:</strong><br />
                        <br />
                        <div style="padding-left: 20px;">
                          Beneficiario : {{payment.bank_account.name}}<br />
                          Banco : {{payment.bank_account.bank_name}}<br />
                          Número de cuenta: {{payment.bank_account.account_number}}<br />
                          Cuenta Clabe: {{payment.bank_account.account_clabe}}
                        </div>
                      </div>
                    </td>
                  </tr>
                  <!--
                  <tr v-if="!payment.bank_account && payment.status != 'ACEPTADO'">
                    <td colspan="3" style="text-align: right">
                        <br />
                        <div style="padding-left: 10px; font-size: 12px;">
                          <strong>PAGO EN EFECTIVO EN EL COLEGIO</strong>
                        </div>
                    </td>
                  </tr>
                  -->
                  <tr>
                    <td colspan="3" style="text-align: right">
                      <br v-if="payment.bank_account " />
                        <CButton v-if="showing > 0 && payment.bank_account && payment.status != 'ACEPTADO'" color="dark" size="sm" @click="uploadModal(payment)">{{payment.status == 'PENDIENTE' ? 'Registrar Pago' : 'Editar Pago'}}</CButton>
                        <CButton v-if="showing > 0 && payment.status == 'ACEPTADO'" color="success" size="sm">{{payment.base_amount != payment.amount ? "PAGADO: " : "PAGADO: "}} {{payment.base_amount != payment.amount ? payment.base_amount : payment.amount | currency}} Pesos</CButton>
                      <br />
                      <span v-if="payment.status != 'ACEPTADO'" :style="'font-size: 12px; color: '+payment.color">{{payment.status}}</span>
                      <hr />
                    </td>
                  </tr>
                </table>
              </CCol>
            </CRow>
            <div class="card-header-actions">
                <CButton v-if="showing == 0" color="success" size="sm" @click="showEnrollment(enrollment)">Ver Viaje</CButton>
                <CButton v-if="showing > 0" color="info" size="sm" @click="backHome">Volver al Inicio</CButton>
            </div>
          </CCardBody>
          </CCard>
      </CCol>
    </CRow>
    <UploadPaymentModal ref="uploadModal" @store="storeProcess"></UploadPaymentModal>
  </div>
</template>
<script>

import store from '../../store'
import ws from '../../services/enrollments';
import UploadPaymentModal from '../../components/enrollments/UploadPaymentModal.vue'

export default {
  name: 'Home',
  components: {
    UploadPaymentModal
  },
  props: {
    
  },
  data () {
    return {
      customer: false,
      enrollments: [],
      payments: [],
      showing: 0,
      travel: false,
    }
  },
  mounted: async function() {
    let response = await ws.getCustomer();

    if(response.type == "success"){
      this.customer = response.data;
    }

    let response2 = await ws.getEnrollments();

    if(response2.type == "success"){
      this.enrollments = response2.data;
    }
  },
  methods: {
    async showEnrollment(enrollment){
      this.showing = enrollment.enrollment_id;
      this.travel = enrollment.travel;

      let response = await ws.getEnrollmentPayments(enrollment.enrollment_id);

      if(response.type == "success"){
        this.payments = response.data;
      }
    },
    uploadModal (payment) {
      this.$refs.uploadModal.storeModal(payment);
    },
    async storeProcess(data){
      let response = await ws.uploadPayment(data, this.showing);
      
      if(response.type == "success"){
        let response = await ws.getEnrollmentPayments(this.showing);

        if(response.type == "success"){
          this.payments = response.data;
        }
      }

      this.showToast(response.type, response.message);
    },
    backHome(){
      this.showing = 0;
      this.travel = false;
      this.payments = [];
    },
    showToast(type, message){
      store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

import axios from 'axios'

export default {
    get: () => axios.get('travels'),
    getBySchool: (tab,period) => axios.get('travels/by-school/'+tab+"/"+period),
    list: () => axios.get('travels/list'),
    show: (code) => axios.get('travels/'+code+'/show'),
    showById: (id) => axios.get('travels/id:'+id+'/show'),
    store: (data) => axios.post('travels/store', data),
    update: (data) => axios.post('travels/update', data),
    close: (data) => axios.post('travels/close', {id: data.id}),
    open: (data) => axios.post('travels/open', {id: data.id}),
    delete: (id) => axios.post('travels/delete', id),
    syncDrive: (id) => axios.get('travels/id:'+id+'/sync'),
    addTravelForm: (data) => axios.post('travels/store-form', data),
    forms: (id) => axios.get('travels/id:'+id+'/forms'),
    formQuestions: (id) => axios.get('travels/id:'+id+'/form-questions'),
    formFieldQuestions: (id) => axios.get('travels/id:'+id+'/form-fields'),
    formAnswers: (id) => axios.get('travels/id:'+id+'/form-answers'),
    storeFormAnswer: (data) => axios.post('travels/store-form-answer', data),
    downloadFormFiltered: (travel_id, travel_form_id, response_ids) => axios.post('travels/filtered-form-download', {travel_id: travel_id, travel_form_id: travel_form_id, response_ids: response_ids}).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    uploadFormFile: (data) => axios.post('travels/uploading-form', data),
    deleteForm: (form_id) => axios.post('travels/delete-form', form_id),

    students: (id) => axios.get('travels/id:'+id+'/students'),
    sendWelcomeMessage: (data) => axios.post('travels/send-welcome-message', data),
    studentsWithPayments: (id) => axios.get('travels/id:'+id+'/active-students-with-payments'),
    activeStudents: (id) => axios.get('travels/id:'+id+'/active-students'),
    activeDetailedStudents: (id) => axios.get('travels/id:'+id+'/active-detailed-students'),
    deactiveStudents: (id) => axios.get('travels/id:'+id+'/deactive-students'),
    waitingStudents: (id) => axios.get('travels/id:'+id+'/waiting-students'),
    activeStudent: (data) => axios.post('travels/id:'+data.id+'/active-student', data),
    deactiveStudent: (data) => axios.post('travels/id:'+data.id+'/deactive-student', data),
    waitStudent: (data) => axios.post('travels/id:'+data.id+'/waiting-student', data),
    updateObservations: (data) => axios.post('travels/id:'+data.id+'/update-observations', data),
    updatePaymentObservations: (data) => axios.post('travels/id:'+data.id+'/update-payment-observations', data),
    payments: (id) => axios.get('travels/id:'+id+'/payments'),
    studentPayments: (id) => axios.get('students/id:'+id+'/payments'),
    studentQuestions: (id) => axios.get('students/id:'+id+'/questions'),
    download: (id, type) => axios.post('students/download', url).then(function(response){
        if(response.type == "success"){
            window.open(url, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    checkPayment: (student_id, payment_id) => axios.get('students/id:'+student_id+'/payment:'+payment_id),
    storePayment: (data) => axios.post('travels/store-payment', data),
    download: (id, type) => axios.post('travels/students/download', {id: id, type: type}).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    downloadFiltered: (id, student_ids) => axios.post('travels/students/filtered-download', {id: id, student_ids: student_ids}).then(function(response){
        if(response.type == "success"){
            window.open(response.data, '_blank');
        }
        else{
            store.commit('toast', {color: response.type, message: response.message});
        }
    }),
    questions: (id) => axios.get('travels/id:'+id+'/questions'),
    specialQuestions: (id) => axios.get('travels/id:'+id+'/special-questions'),
    storeQuestion: (data) => axios.post('travels/store-question', data),
    updateQuestion: (data) => axios.post('travels/update-question', data),
    deleteQuestion: (id) => axios.post('travels/delete-question', {id: id}),
    getOptions: (question_id) => axios.get('travels/id:'+question_id+'/get-question-options'),
    storeAnswer: (data) => axios.post('travels/store-answer', data),

    //Divide
    divide: (data) => axios.post('travels/divide', data),

}